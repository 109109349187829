
import Vue from 'vue';

export default Vue.extend({
  name: 'Loader',
  props: {
    size: { type: Number, default: 200 },
    width: { type: Number, default: 2 },
    color: { type: String, default: 'primary' },
    isLoading: { type: Boolean, default: false },
    className: { type: String, default: '' },
  },
});


import Vue from "vue";

export default Vue.extend({
  name: "SelectMassageTypeBtns",
  components: {},
  props: {
    massageTypes: {
      type: Array,
      required: true,
    },
    selectedMassageType: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      massageType: this.selectedMassageType,
      massageTypeFilterHint: undefined,
    };
  },
  watch: {
    massageType() {
      this.$emit("set-massage-type", this.massageType);
      this.massageTypeFilterHint = undefined;
    },
  },
  methods: {
  },
});


import Vue from 'vue';
import Loader from '@/components/ui-elements/Loader.vue';

export default Vue.extend({
  name: 'EnterDetails',
  components: {Loader},
  props: {
    initiatorEmail: {
      type: String,
      required: true,
    },
    selectedDoctor: {
      type: Object,
      required: true,
    },
    selectedTime: {
      type: String,
      required: true,
    },
    selectedAppointmentType: {
      type: Object,
      required: true,
    },
    patientTypes: {
      type: Array,
      required: true,
    },
    selectedPatientType: {
      type: String,
      required: true,
    },
    isLoading: { type: Boolean, default: false },
  },
  data() {
    return {
      isValid: false,
      errorMessage: '',
      email: '',
      schedulingForVariants: [
        {label: 'Scheduling for me', value: 'me'},
        {label: 'Scheduling for someone else', value: 'else'},
      ],
      firstNameRules: [
        v => !!v || 'First Name is required',
        v => /^[A-Za-z]+$/.test(v) || 'First Name must contain only letters',
        v => v.length <= 50 || 'First Name must be less than 50 characters',
      ],
      lastNameRules: [
        v => !!v || 'Last Name is required',
        v => /^[A-Za-z]+$/.test(v) || 'Last Name must contain only letters',
        v => v.length <= 50 || 'Last Name must be less than 50 characters',
      ],
      dateOfBirthRules: [
        v => !!v || 'Date of Birth is required',
      ],
      sexFieldRules: [
        v => !!v || 'Sex is required',
      ],
      phoneNumberRules: [
        v => !!v || 'Phone Number is required',
      ],
      zipCodeRules: [
        v => !!v || 'Zip Code is required',
      ],
      selectedVariant: 'me',
      FOR_ME: 'me',
      FOR_ELSE: 'else',
      firstName: '',
      lastName: '',
      phoneNumber: '',
      zipCode: '',
      sex: '',
      dateOfBirth: '',
      idDOBCorrect: false,
      dateOfBirthHint: undefined,
      comments: '',
      insuranceStatus: '',
    }
  },
  created() {
    // Function to handle first open
    this.email = this.initiatorEmail;
  },
  computed: {
    isForMe() {
      return this.selectedVariant === this.FOR_ME
    },
    patientTypeSelected() {
      return this.patientTypes.find(type => type.value === this.selectedPatientType);
    }
  },
  methods: {
    bookAppointment() {
      const payload = {
        firstName: this.firstName,
        lastName: this.lastName,
        phoneNumber: this.phoneNumber,
        zipCode: this.zipCode,
        sex: this.sex,
        dateOfBirth: this.dateOfBirth,
        comments: this.comments,
        insuranceStatus: this.insuranceStatus,
      };

      this.validate();

      if (this.isValid) {
        this.$emit('book-appointment', payload);
      } else {
        return false;
      }

    },
    validate() {
      this.isValid = this.$refs.form.validate();
    },
  }
});

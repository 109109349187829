
import Vue from "vue";
import ExtendedDoctorCard from "@/components/cards/ExtendedDoctorCard.vue";
import Loader from '@/components/ui-elements/Loader.vue';

export default Vue.extend({
  name: "ConfirmTime",
  components: {Loader, ExtendedDoctorCard },
  props: {
    patientTypes: {
      type: Array,
      required: true,
    },
    selectedDoctor: {
      type: Object,
      required: true,
    },
    selectedTime: {
      type: String,
      required: true,
    },
    selectedAppointmentType: {
      type: Object,
      required: true,
    },
    selectedPatientType: {
      type: String,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      allowReceiveEmails: true,
      isFormValid: false,
      email: "",
      emailHint: undefined,
      patientType: undefined,
      emailRules: [
        (v) => !!v || "Email is required",
        (v) => /.+@.+\..+/.test(v) || "Email must be valid",
      ],
    };
  },
  watch: {
    selectedPatientType() {
      if (this.patientType !== this.selectedPatientType)
        this.patientType = this.selectedPatientType;
    },
    patientType() {
      if (this.patientType !== this.selectedPatientType)
        this.$emit("set-patient-type", this.patientType);
    },
  },
  mounted() {
    this.patientType = this.selectedPatientType;
  },
  computed: {
    time() {
      return this.$dayjs(this.selectedTime)
        .tz("US/Eastern")
        .format("dddd, MMMM D, h:mma");
    },
    patientTypeSelected() {
      return this.patientTypes.find(
        (type) => type.value === this.selectedPatientType
      );
    },
  },
  methods: {
    saveEmail() {
      if (this.$refs.form.validate()) {
        this.$emit("set-email", this.email);
      } else {
        this.emailHint = "Please enter a valid email address";
      }
    },
    forwardToPrivacyPolicyPage() {
      const message = {
        type: "REDIRECT_TO_URL",
        url: "/privacy-policy",
        newTab: true,
      };
      window.parent?.postMessage(message, "*");
    },
  },
});

export const MESSAGE_TYPES = [
  {
    id: 1,
    name: 'Swedish Massage 60 Mins $170',
    duration: 60,
    description:
      'Great for overall relaxation. Uses long effleurage strokes and gentle pressure to improve circulation and diminish stress. This is perfect for first timers.',
  },
  {
    id: 2,
    name: 'Swedish Massage 90 Mins $215',
    duration: 90,
    description:
      'Great for overall relaxation. Uses long effleurage strokes and gentle pressure to improve circulation and diminish stress. This is perfect for first timers.',
  },
  {
    id: 3,
    name: 'Sports Massage 60 Mins $220',
    duration: 60,
    description:
      'An advanced therapeutic treatment blending Eastern and Western sports massage techniques to enhance performance and recovery. This approach improves conditioning, eases muscle tension, boosts flexibility, and fosters mental focus and rejuvenation, vital for athletic excellence.',
  },
  {
    id: 4,
    name: 'Sports Massage 90 Mins $265',
    duration: 90,
    description:
      'An advanced therapeutic treatment blending Eastern and Western sports massage techniques to enhance performance and recovery. This approach improves conditioning, eases muscle tension, boosts flexibility, and fosters mental focus and rejuvenation, vital for athletic excellence.',
  },
  {
    id: 5,
    name: 'Hot Stone Massage W/ Deep Tissue (60 Mins): $195',
    duration: 60,
    description:
      'A complete calming massage, warm hot stones are worked over the body, focusing on pressure points that include the feet, legs, back and shoulders. All signs of stress and muscle tension are eased and the body’s energy flow is restored, providing a sense of balance to the entire body and mind.',
  },
  {
    id: 6,
    name: 'Hot Stone Massage W/ Deep Tissue (90 Mins): $240',
    duration: 90,
    description:
      'A complete calming massage, warm hot stones are worked over the body, focusing on pressure points that include the feet, legs, back and shoulders. All signs of stress and muscle tension are eased and the body’s energy flow is restored, providing a sense of balance to the entire body and mind.',
  },
  {
    id: 7,
    name: 'Hot Stone Massage W/ Swedish (60 Mins): $185',
    duration: 60,
    description:
      'A complete calming massage, warm hot stones are worked over the body, focusing on pressure points that include the feet, legs, back and shoulders. All signs of stress and muscle tension are eased and the body’s energy flow is restored, providing a sense of balance to the entire body and mind.',
  },
  {
    id: 8,
    name: 'Hot Stone Massage W / Swedish (90 Mins): $230',
    duration: 90,
    description:
      'A complete calming massage, warm hot stones are worked over the body, focusing on pressure points that include the feet, legs, back and shoulders. All signs of stress and muscle tension are eased and the body’s energy flow is restored, providing a sense of balance to the entire body and mind.',
  },
  {
    id: 9,
    name: 'Deep Tissue Massage 60 Mins: $180',
    duration: 60,
    description:
      'Ideal for targeting specific areas of tension, relieving chronic pain and increasing range of motion.',
  },
  {
    id: 10,
    name: 'Deep Tissue Massage 90 Mins: $225',
    duration: 90,
    description:
      'Ideal for targeting specific areas of tension, relieving chronic pain and increasing range of motion.',
  },
  {
    id: 11,
    name: 'Prenatal Massage',
    duration: 60,
    description:
      'A therapy specially designed to meet the unique needs of expectant mothers. We understand that pregnancy is a period of intense physical and emotional transformations, and our prenatal massage aims to provide relief, comfort, and well-being during this special phase. During pregnancy, the body undergoes numerous changes that can result in discomfort and tension.',
  },
];
















































































































































































































import TextCard from '@/components/cards/TextCard.vue';
import TreatmentNotesCard from '@/components/cards/TreatmentNotesCard.vue';
import {mdiClose, mdiPause, mdiPlay} from '@mdi/js';
import Vue from 'vue';
import {mapActions, mapGetters, mapState} from 'vuex';

export default Vue.extend({
  name: 'TreatmentDialog',
  components: { TextCard, TreatmentNotesCard },
  props: {
    name: String,
    administrator: { type: String, required: true},
    roomName: String,
    allocatedTime: Number,
    time: Number,
    cardState: String,
    notes: Array,
    appointment: { type: Object, required: true },
    appointmentId: String,
    treatment: { type: Object, required: true },
    isDisabled: { type: Boolean, default: false },
  },
  data() {
    return {
      dialog: true,
      closeIcon: mdiClose,
      pauseIcon: mdiPause,
      playIcon: mdiPlay,
      overHour: false,
      allocatedOverHour: false,
      doctorOfTreatment: null,
      allowedToClose: true,
      count: 0,
    }
  },
  created(){
    this.doctorOfTreatment = this.doctors.find( k => k.uuid === this.administrator );

    const notesPayload = {
      appointment_treatment_uuid: this.treatment.uuid,
    };
    this.$store.dispatch('LOAD_NOTES_BY_TREATMENT', {params: notesPayload});

    if (this.dialog) {
      this.count = 0;
      clearInterval(this.interval);
      this.interval = setInterval(() => {
        this.count++;
      }, 30)
    }
  },
  destroyed() {
    this.$store.commit('setNotesForTreatmentDialog', {notes: []});
    this.$store.commit('setFlagsForDisplay', {notes: []});
    this.$store.commit('setTractionFlagsForDisplay', {notes: []});
  },
  watch: {
    doctorOfTreatment: 'updateDoctor',
  },
  computed: {
    ...mapGetters(['notesForCurrentOpenTreatment', 'currentOpenFlags', 'currentOpenTractionFlags', 'currentOpenProfiles']),
    ...mapState({
      // @ts-ignore
      doctors: state => state.doctors,
    }),
    currentGlobalNotes() {
      console.log(this.notesForCurrentOpenTreatment);
      return this.notesForCurrentOpenTreatment.filter((note) => !note.treatment);
    },
    currentLocalNotes() {
      return this.notesForCurrentOpenTreatment.filter((note) => !!note.treatment);
    },
    relatedDoctorsToTreatment()
    {
      const currentTreatmentUUID = this.treatment.treatment.uuid;

      return this.doctors.filter((doctor) => {
        return doctor.treatments.some(({uuid}) => uuid === currentTreatmentUUID) && doctor.is_enabled === 'yes';
      })
    },
    stateIcon(): string {
      const playStates = ['default', 'completed'];
      return playStates.includes(this.cardState)
        ? this.playIcon
        : this.pauseIcon;
    },
    timeInTreatment() {
      const {
        started_at: timeStarted, ended_at: timeEnded,
        resumed_at: timeResumedAt, status, time_elapsed: timeElapsedBeforeSeconds
      } = this.treatment;

      const isPaused = status === 'paused'
      const timeElapsedBefore = timeElapsedBeforeSeconds * 1000
      const timeResumed =
          (!!timeStarted && !isPaused && !!timeResumedAt)
              ? this.$dayjs.utc(timeResumedAt).valueOf()
              : 0

      const timeSinceResume =
          (!!timeStarted && !timeEnded && !isPaused)
              ? this.time.valueOf() - timeResumed
              : 0

      return timeElapsedBefore + timeSinceResume
    },
    timeFormatted(): any {
      return this.$options.filters.timestampString(this.time);
    },
    allocatedTimeFormatted(): any {
      // MULTIPLY BY 60
      const dur = this.$dayjs.duration(this.allocatedTime).asMilliseconds();
      const format = dur >= 3600000 ? 'hh:mm' : 'mm:ss';
      this.allocatedOverHour = format === 'hh:mm';
      return this.$dayjs.utc(dur).format(format);
    },
  },
  methods: {
    ...mapActions([
      'UPDATE_DOCTOR_OF_APPOINTMENT_TREATMENT',
    ]),
    closeModal() {
      if (this.count > 20 && this.allowedToClose) {
        this.$emit('close');
      }
    },
    setAllowedToClose(value: boolean) {
      this.allowedToClose = value;
    },
    togglePlayState(event: any): void {
      event.target.blur();
      if (this.cardState === 'default') {
        this.$emit('play');
      } else {
        this.$emit('pause');
      }
    },
    handleCompleteTreatment() {
      this.$emit('complete-treatment');
      this.$emit('close');
    },
    async refreshAppointment() {
      const payload = {
        appointmentId: this.appointmentId,
      };

    },
    async updateDoctor() {

      if (this.doctorOfTreatment.uuid !== this.administrator){
        const result = await this.UPDATE_DOCTOR_OF_APPOINTMENT_TREATMENT({ appointmentId: this.appointmentId, params: {
            treatment: this.treatment.uuid,
            doctor: this.doctorOfTreatment.uuid,
          }})

        if (!result){
          this.doctorOfTreatment = this.administrator
        }
      }

    }
  }
});


import Vue from "vue";
import { debounce } from "lodash";
import axios, { CancelTokenSource } from "axios";
import posthog from "posthog-js";
import Loader from '@/components/ui-elements/Loader.vue';

export default Vue.extend({
  name: "InsuranceDetails",
  components: {Loader},
  props: {
    patientDetails: {
      type: Object,
      required: true,
    },
    selectedDoctor: {
      type: Object,
      required: true,
    },
    selectedTime: {
      type: String,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isValid: false,
      errorMessage: "",
      providerQueryString: "",
      insuranceProvider: undefined,
      insuranceMemberId: "",
      insuranceProviders: [],
      insurancePlans: [],
      isLoadingProviders: false,
      isLoadingPlans: false,
      cancelTokenSource: null as CancelTokenSource | null,
    };
  },
  computed: {
    scheduledTimeFormated() {
      return this.$dayjs(this.selectedTime)
        .tz("US/Eastern")
        .format("dddd, MMMM D [and] h:mma");
    },
  },
  watch: {
    planType(newVal) {
      this.planId = newVal;
    },
    insuranceProvider(newVal) {
      console.log("Insurance Provider:", newVal);
      this.providerQueryString =
        this.insuranceProviders.find(
          (provider) => provider.foreign_id === newVal
        )?.name ?? "";
    },
  },
  methods: {
    checkBenefits() {
      console.log("Check Benefits");
      const provider = this.insuranceProviders.find(
        (provider) => provider.foreign_id === this.insuranceProvider
      );
      // const plan = this.insurancePlans.find((plan) => plan.id === this.planId);

      const payload = {
        insuranceProvider: provider.name,
        insuranceMemberId: this.insuranceMemberId,
        // planType: plan?.name,
        // planId: plan?.id,
      };
      this.$emit("check-benefits", payload);
    },
    validate() {
      this.isValid = this.$refs.form.validate();
    },
    cancelLastProvidersRequest() {
      if (this.cancelTokenSource) {
        this.cancelTokenSource.cancel("Operation canceled due to new request.");
      }
    },
    fetchInsuranceProviders: debounce(async function (query) {
      if (!query) {
        this.insuranceProviders = [];
        return;
      } else if (query === this.providerQueryString) {
        return;
      }

      this.isLoadingProviders = true;

      // Cancel the previous request if it exists
      if (this.cancelTokenSource) {
        this.cancelTokenSource.cancel("Operation canceled due to new request.");
      }

      // Create a new cancel token
      this.cancelTokenSource = axios.CancelToken.source();

      try {
        const result = await this.$store.dispatch(`LOAD_INSURANCES`, {
          params: { name: query },
          cancelToken: this.cancelTokenSource.token,
        });

        if (result) {
          this.insuranceProviders = result;
        } else {
          this.insuranceProviders = [{ name: query, foreign_id: 0 }];
        }
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log("Request canceled", error.message);
        } else {
          console.error("Error fetching insurance providers:", error);
        }
      } finally {
        this.isLoadingProviders = false;
      }
    }, 400),
    async requestInsurancePlans(foreignId) {
      // Cancel the previous request if it exists
      if (this.cancelTokenSource) {
        this.cancelTokenSource.cancel("Operation canceled due to new request.");
      }

      if (this.insuranceProviders[0].foreign_id === 0) {
        this.insuranceProvider = undefined;
      } else {
        const payload = {
          foreignId: foreignId ?? this.insuranceProvider,
        };

        this.isLoadingPlans = true;
        try {
          const result = await this.$store.dispatch(
            `LOAD_INSURANCE_PLANS`,
            payload
          );

          if (result && result.length > 0) {
            this.insurancePlans = result;
          }
        } catch (error) {
          console.error("Error fetching insurance plans:", error);
        } finally {
          this.isLoadingPlans = false;
        }
      }
    },
    addCustomPlan(val) {
      this.insurancePlans = [{ name: val, id: 0 }];
    },
    forwardToThankYouPage() {
      const message = {
        type: "REDIRECT_TO_URL",
        url: "/thank-you-appointment",
      };
      posthog.capture("redirect_to", {
        widget_type: "booking_widget",
        view_name: "Thank You Page",
      });
      window.parent?.postMessage(message, "*");
    },
  },
});


import Vue from "vue";
import ExtendedDoctorCard from "@/components/cards/ExtendedDoctorCard.vue";
import { mapGetters } from "vuex";
import GoogleMap from "@/components/modules/GoogleMap.vue";
import { BOOKING_SERVICES_NAMES } from "@/constants/bookingWidgetScreens";
import SelectMassageType from '@/components/ui-elements/SelectMassageType.vue';
import SelectMassageTypeBtns from '@/components/ui-elements/SelectMassageTypeBtns.vue';

export default Vue.extend({
  name: "SelectDoctor",
  components: {SelectMassageTypeBtns, SelectMassageType, GoogleMap, ExtendedDoctorCard },
  props: {
    selectedAppointmentType: {
      type: String,
      required: true,
    },
    selectedPatientType: {
      type: String,
      required: true,
    },
    selectedLocation: {
      type: String,
      required: true,
    },
    nextPage: {
      type: String,
      required: true,
    },
    selectedMassageType: {
      type: Number,
      default: null,
    },
    patientTypes: {
      type: Array,
      required: true,
    },
    locations: {
      type: Array,
      required: true,
    },
    massageTypes: {
      type: Array,
      required: true,
    },
    apptTypes: {
      type: Array,
      required: true,
    },
    doctors: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      patientType: this.selectedPatientType,
      apptType: this.selectedAppointmentType,
      location: this.selectedLocation,
      massageType: this.selectedMassageType,
      massageTypeFilterHint: undefined,
      showApptDetails: true,
      openMapDoctorUuid: "",
      isMapOpen: false,
    };
  },
  watch: {
    patientType: function () {
      this.$emit("set-patient-type", this.patientType);
    },
    apptType() {
      this.$emit("set-selected-type", this.apptType);
    },
    location() {
      this.$emit("set-selected-location", this.location);
    },
    selectedLocation() {
      this.location = this.selectedLocation;
    },
    massageType() {
      this.$emit("set-massage-type", this.massageType);
      this.massageTypeFilterHint = undefined;
    },
  },
  created() {
    this.patientType = this.selectedPatientType;
    this.apptType = this.selectedAppointmentType;
    this.location = this.selectedLocation;
  },
  computed: {
    ...mapGetters(["bookingTemplates", "bookingLoading"]),
    isDesktop() {
      return window.innerWidth >= 1023;
    },
    locationsForFilterMap() {
      return this.locations.filter(({ id }) => id !== null);
    },
    selectedMassageTypeObj() {
      return this.massageTypes.find(({ id }) => id === this.massageType);
    },
    showMassageSection() {
      return this.selectedAppointmentType === BOOKING_SERVICES_NAMES.MASSAGE;
    },
    isMassageTypeSelected() {
      return this.selectedMassageType !== null;
    },
    showDoctors() {
      return (this.selectedAppointmentType === BOOKING_SERVICES_NAMES.MASSAGE && this.isMassageTypeSelected)
          || this.selectedAppointmentType !== BOOKING_SERVICES_NAMES.MASSAGE;
    },
    locationsCount() {
      const docLocations = [];

      this.doctors.forEach((doc) => {
        if (!docLocations.includes(doc.locationId)) {
          docLocations.push(doc.locationId);
        }
      });

      return docLocations.length;
    },
    selectedApptTypesObj() {
      return this.apptTypes.find(
        (type) => type.value === this.selectedAppointmentType
      );
    },
  },
  methods: {
    selectDoctor(doc, hash = null) {
      if (
        this.selectedApptTypesObj.name === BOOKING_SERVICES_NAMES.MASSAGE &&
        !this.selectedMassageType
      ) {
        this.massageTypeFilterHint = "Please, select massage type first!";

        return false;
      } else {
        this.massageTypeFilterHint = undefined;
      }

      this.$emit("set-selected-doctor", doc);
      this.$emit("next-step", this.nextPage, hash);
    },

    clickOnInsurance(paymentType: string) {
      if (paymentType === "Insurance") {
        const message = {
          type: "CLICK_CHIP",
          target: "insurance",
        };
        window.parent?.postMessage(message, "*");
      }
    },
    openedMap(doctorUuid: string) {
      this.openMapDoctorUuid = doctorUuid;
    },
    toggleMap() {
      this.isMapOpen = !this.isMapOpen;
    },
    setMassageType(massageType: number) {
      this.massageType = massageType;
    },
  },
});

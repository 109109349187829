export const BOOKING_SCREENS = {
  CHOOSE_APPT_TYPE: 'CHOOSE_APPT_TYPE',
  CHOOSE_DOCTOR: 'CHOOSE_DOCTOR',
};

export const BOOKING_SERVICES_NAMES = {
  MASSAGE: 'Massage Therapy',
};

export const BOOKING_SERVICES = [
  {
    id: 1,
    name: 'Chiropractic',
    img: require('@/assets/images/chiropractic.png'),
    paymentType: 'Insurance',
    infoText:
      'Chiropractic care is a safe and effective approach that offers a hands-on, holistic approach to treating musculoskeletal disorders, focusing on diagnosing, treating, and preventing problems through manual adjustments, therapies, and lifestyle advice, with the goal of restoring mobility and natural healing.',
  },
  {
    id: 2,
    name: 'Occupational Therapy',
    img: require('@/assets/images/occupational_therapy.png'),
    paymentType: 'Insurance',
    infoText:
      'Occupational therapists play a vital role in helping individuals manage musculoskeletal disorders, focusing on improving daily function, reducing pain, and promoting independence through tailored interventions and activities.',
  },
  {
    id: 3,
    name: 'Physical Therapy',
    img: require('@/assets/images/phisical_therapy.png'),
    paymentType: 'Insurance',
    infoText:
      'Physical therapy plays a crucial role in managing and improving function for musculoskeletal conditions, utilizing a variety of methods to alleviate pain, restore mobility, and enhance overall well-being through exercises and manual therapies.',
  },
  {
    id: 4,
    name: 'Acupuncture',
    img: require('@/assets/images/Acupuncture.png'),
    paymentType: 'Insurance',
    infoText:
      'Acupuncture is a practice rooted in Traditional Chinese Medicine that uses thin needles inserted into specific points (acupoints) on the skin to stimulate the body\'s natural healing abilities and alleviate various health conditions and symptoms, particularly pain.',
  },
  {
    id: 5,
    name: 'Massage Therapy',
    img: require('@/assets/images/MassageTherapy.png'),
    paymentType: 'Cash',
    infoText:
      'Massage therapy is a practice where a trained professional manipulates soft tissues like muscles, tendons, ligaments, and skin using techniques like kneading, friction massage, and cross-fiber massage to promote well-being, reduce stress, and relieve pain and tension.',
  },
];
